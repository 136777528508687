import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import NavSection from '../components/nav-section';
import Footer from '../components/footer';
import Layout from '../components/layout';

class EventsGeneralPageMixed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  incrementQuantity = e => {
    e.preventDefault();
    this.setState({ quantity: parseInt(this.state.quantity) + 1 });
  };

  handleCounter = e => {
    let targetValue = e.target.value;
    let reg = new RegExp('^[0-9]*$');

    if (reg.test(targetValue) && targetValue > 0) {
      this.setState({ quantity: targetValue });
    } else {
      this.setState({ quantity: 0 });
    }
  };

  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
    const {
      featuredImage,
      articleAuthor,
      articleGalleryImage1,
      articleGalleryImage2,
      articleGalleryImage3,
      articleIntro,
      articleLeftColumn1,
      articleOneColumn2,
      articleQuote,
      articleRightColumn1,
      articleSubTitle1,
      articleSubTitle2,
      articleTitle,
      articlesYouMightLikeLeft,
      articlesYouMightLikeRight,
    } = get(this, 'props.data.contentfulEventsGeneralArticleMixedLayout');
    
    const footer = get(this, 'props.data.allContentfulFooter.edges')

    return (
      <Layout>
        <NavSection navMenus={navMenus} navTheme="nav-white" />
        <Helmet title={`Events - ${articleTitle}`} />


        <section id="SinglePostHeader" className="pt-5 pb-5">
          <div
            className="hero-section hero-banner special-slider events-header"
            style={{ backgroundImage: `url(${featuredImage.file.url})` }}
          ></div>
        </section>

        <section id="PostInfo" className="pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-8 offset-2 text-center">
                <h1 className="red font-tri">{articleTitle}</h1>
                <p className="font-gtaM text-uppercase">{articleAuthor.name}</p>
              </div>
            </div>
            <div className="row mt80">
              <div className="col-sm-12 col-md-6">
                <p>
                  {articleIntro.articleIntro}
                </p>
              </div>
              <div className="col-sm-12 col-md-6">
                <h3 className="font-bigcas red border-top border-bottom">
                  {articleQuote.articleQuote}
                </h3>
              </div>
              <div className="col-md-12 mt20">
                <h2 className="font-gtaM red mt60 mb30">
                  {articleSubTitle1}
                </h2>
              </div>
              <div className="col-sm-12 col-md-6">
                <p>
                  {articleLeftColumn1.articleLeftColumn1}
                </p>
              </div>
              <div className="col-sm-12 col-md-6">
                <p>
                  {articleRightColumn1.articleRightColumn1}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="PostGallery">
          <div className="container">
            <div className="row mt30">
              <div className="col-md-12">
                <img className="w-100 h-100" src={articleGalleryImage1.file.url} alt="" />
              </div>
              <div className="col-md-6">
                <img className="w-100 h-100" src={articleGalleryImage2.file.url} alt="" />
              </div>
              <div className="col-md-6">
                <img className="w-100 h-100" src={articleGalleryImage3.file.url} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section id="PostInfo" className="pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1 mt20">
                <h2 className="font-gtaM red mt60 mb30">{articleSubTitle2}</h2>
              </div>
              <div className="col-sm-12 col-md-10 offset-md-1">
                <p
                  dangerouslySetInnerHTML={{
                    __html: articleOneColumn2.articleOneColumn2
                  }}
                ></p>
              </div>
            </div>
          </div>
        </section>

        <hr className="hr-standard-events mt40" />

        <section id="ArticleSuggestionsSection" className="pb-5 pt-5 mb40">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="font-tri red text-center">
                  Articles you might like
                </h1>
              </div>
              <div className="col-md-6 mt50 text-left">
                <a href={`/stories/${articlesYouMightLikeLeft.slug}`}>
                  <img className="w-100" src={articlesYouMightLikeLeft.featuredImage.file.url} alt="" />
                  <h3 className="red mt20 font-bigcas">
                    {articlesYouMightLikeLeft.articleTitle}
                  </h3>
                  <p className="font-gtaM event-date text-uppercase mt20 black">
                    by {articlesYouMightLikeLeft.articleAuthor.name}
                  </p>
                </a>
              </div>
              <div className="col-md-6 mt50 text-left">
                <a href={`/stories/${articlesYouMightLikeRight.slug}`}>
                  <img className="w-100" src={articlesYouMightLikeRight.featuredImage.file.url} alt="" />
                  <h3 className="red mt20 font-bigcas">
                    {articlesYouMightLikeRight.articleTitle}
                  </h3>
                  <p className="font-gtaM event-date text-uppercase mt20 black">
                    by {articlesYouMightLikeRight.articleAuthor.name}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section>

        <Footer 
          footer={footer}
        />
      </Layout >
    );
  }
}

export default EventsGeneralPageMixed;

export const pageQuery = graphql`
  query EventsGeneralPageMixedQuery($slug: String) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulEventsGeneralArticleMixedLayout(slug: { eq: $slug }) {
      featuredImage {
        file {
          url
        }
      }
      slug
      articleTitle
      articleAuthor {
        name
      }
      articleIntro {
        articleIntro
      }
      articleQuote {
        articleQuote
      }
      articleSubTitle1
      articleLeftColumn1 {
        articleLeftColumn1
      }
      articleRightColumn1 {
        articleRightColumn1
      }
      articleGalleryImage1 {
        file {
          url
        }
      }
      articleGalleryImage2 {
        file {
          url
        }
      }
      articleGalleryImage3 {
        file {
          url
        }
      }
      articleSubTitle2
      articleOneColumn2 {
        articleOneColumn2
      }
      articlesYouMightLikeLeft {
        ... on ContentfulEventsGeneralArticleMixedLayout {
          featuredImage {
            file {
              url
            }
          }
          articleTitle
          slug
          articleAuthor {
            name
          }
        }
      }
      articlesYouMightLikeRight {
        ... on ContentfulEventsGeneralArticleMixedLayout {
          featuredImage {
            file {
              url
            }
          }
          articleTitle
          slug
          articleAuthor {
            name
          }
        }
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
